<template>
  <v-row class="mb-16">
    <!-- header -->
    <mini-header :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true"></mini-header>
    <!-- end header -->
    <v-col cols="12" class="pa-0 pb-10">
      <div class="d-flex">
        <div cols="6" class="pa-0" style="flex: 1">
          <v-card flat>
            <v-card-text class="py-6 px-0">
              <v-col class="px-5">
                <span class="d-block body-1 fontBody--text font-weight-bold mb-2">Reglas de uso del la campaña</span>
                <!-- alert -->
                <v-alert class="inf-alert-blue primary--text rounded-md mt-5" dense text>
                  <div class="d-flex pa-0 my-1 pt-1">
                    <v-icon color="primary" size="22">mdi-information-outline</v-icon>
                    <div class="ml-4">
                      <span class="body-2 align-center" style="line-height: 14px;">Estás a punto de solicitar una
                        <span class="font-weight-bold">plantilla personalizada.</span> Una vez que hayas hecho la solicitud, nuestro equipo de
                        <span class="font-weight-bold">soporte se pondrá en contacto contigo</span> una vez que la <span class="font-weight-bold">plantilla esté lista.</span>
                      </span>
                    </div>
                  </div>
                </v-alert>
                <!-- end alert -->
                <div class="mt-5">
                  <span class="d-block mt-3 body-1 fontBody--text font-weight-bold mb-2">Información de la campaña</span>
                  <span class="d-block body-2 mt-2">Nombre</span>
                  <v-text-field v-model="name" outlined single-line dense readonly hide-details />
                </div>
              </v-col>

              <v-divider class="my-7" />
              <v-col class="px-5">
                <span class="d-block mb-4 body-1 fontBody--text font-weight-bold mb-2">Información de envío</span>
                <v-row no-gutters>
                  <v-col cols="12" class="mb-5">
                    <span class="d-block">Tipo de documento</span>
                    <v-autocomplete label="Selecciona un tipo de documento" :items="documentTypesList.filter(({country}) => country === $store?.state?.auth?.account?.country )" item-value="id" item-text="name" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" single-line outlined dense hide-details>
                      <template v-slot:item="data">
                        <v-list-item-title class="font-weight-regular px-2">{{data.item.name}}</v-list-item-title>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="5" class="pl-0 pr-2">
                    <span class="d-block">Fecha de inicio</span>
                    <v-menu v-model="menu" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="290">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="date" v-bind="attrs" v-on="on" append-icon="mdi-calendar-month" outlined single-line dense readonly hide-details />
                      </template>
                      <v-date-picker v-model="date" @input="menu=false" min="2020-01-01" no-title :first-day-of-week="1" type="date" locale="es" max-width="270" color="primary" />
                    </v-menu>
                  </v-col>
                  <v-col cols="5" class="pr-0 pl-2">
                    <span class="d-block">Fecha de término <v-chip class="ml-3 px-1" x-small color="off">Opcional</v-chip></span>
                    <v-menu v-model="menu1" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="290">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="date1" v-bind="attrs" v-on="on" append-icon="mdi-calendar-month" outlined single-line dense readonly hide-details />
                      </template>
                      <v-date-picker v-model="date1" @input="menu1=false" min="2020-01-01" no-title :first-day-of-week="1" type="date" locale="es" max-width="270" color="primary" />
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
            </v-card-text>
          </v-card>
        </div>
        <div class="ml-5" style="flex: 1.2; position: relative;">
          <v-col cols="6" class="pa-0 pr-15" style="position: fixed;">
            <v-card class="mb-16" flat height="calc(100% - 600px)">
              <v-toolbar color="transparent" flat height="46">
                <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">
                  <v-row align="center" no-gutters>
                    <v-icon size="18" left color="secondary">mdi-eye</v-icon>
                    Previsualización
                  </v-row>
                </v-toolbar-title>
              </v-toolbar>
            <v-divider />
            <v-card-text class="pa-0 py-5  overflow-y-auto" :style="`max-height: ${$vuetify.breakpoint.height <= 720 ? '460px' : 'calc(100vh - 260px)'} !important;`">
              <v-img
                :src="require(`@/assets/product-outbound/examples-templates/img-1.png`)"
                min-width="100%"
              />
            </v-card-text>
            </v-card>
          </v-col>
        </div>
      </div>
    </v-col>
    <div class="bgSearch mt-6 pa-0 pagination-footer">
      <v-divider />
      <v-col class="px-0">
        <v-row>
          <v-col cols="6" class="text-left" :style="$store.state.base.isExpandNavigationDrawer ? 'padding-left: 250px' : 'padding-left: 90px'">
            <v-btn
              class="font-weight-semibold"
              :to="{name: 'TemplateList'}"
              color="primary"
              text
            >Cancelar</v-btn>
          </v-col>
          <v-spacer />
          <v-col cols="6" class="text-end">
            <v-btn
              class="px-4 ml-2 mr-6"
              @click="$router.push({ name: 'EditTemplate', params: { id: 'template-1'}})"
              outlined
            >
            Atrás
            </v-btn>
            <v-btn
              class="px-4 ml-2 mr-6"
              @click="dialog=true"
              outlined
            >
            Enviar email de prueba
            </v-btn>
            <v-btn
              class="px-4 ml-2 mr-6"
              :to="{name: 'TemplateList'}"
              color="primary"
            >
            Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </div>

    <!-- dialog send mail confirm -->
    <v-dialog v-model="dialog" width="410" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Enviar email de prueba</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialog=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-5 px-5 fontBody--text">
          <v-img class="mt-2" contain :height="180" :src="require(`@/assets/backgrounds/modal-send-campaing.svg`)" />
          <v-col class="text-left mt-5">
            Se enviará a tu email una prueba sin documento adjunto, desde envio.dte@go.axteroid.com
            <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
            <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialog=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="dialog=false" :disabled="confirm.toLowerCase() !== 'confirmar'">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog send mail -->
  </v-row>
</template>
<script>
import MiniHeader from '@/components/commons/MiniHeader'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import documentTypesList from '@/collections/documentTypes'

export default {
  components: {
    MiniHeader
  },
  mixins: [
    GenericViewMixin
  ],
  data: () => ({
    documentTypesList: documentTypesList,
    name: '',
    type: '',
    date: null,
    date1: null,
    menu: false,
    menu1: false,
    confirm: '',
    dialog: false,
    instance: {},
    breadcrumbs: {
     main: 'Plantillas',
     children: [{ text: 'Nueva plantilla de email'}, { text1: 'Personalización' }, { text2: 'Ajustes de envío' }]
   }
  })
}
</script>